const routes = {
  INITIAL: "/",
  SIGNUP: "/signup",
  DASHBOARD: "/dashboard",
  STUDENTLIST: "/service-center",
  SERVICEEXP: `/service-center`,
  CALENDER: "/calender",
  NOTIFICATION: "/notification",
  COURSE: "/course",
  WEBINAR: `/reports`,
  CATEGORY: `/service`,
  EXPERTS: `/manage-service`,
  VENDORS: `/vendors`,
  REQUESTEXP: "/requests",
  SERVICEMANAGE: "/manage-service",
  INVENTORY: "/inventory",
  INVENTORYEXP: "/inventory",
  PRODUCTCREATE: "/inventory-product/create",
  PRODUCTEDIT: "/inventory-product/edit",
  SERVICECREATE: "/service-create",
  SERVICEEDIT: "/service-edit",
  SOS: "/sos",
  EVENTS: "/events",
  SERVICEREPORTS: "/service-reports",
  PRODUCTREPORTS: "/product-reports",
  MODERATORS: "/moderators",
  SUBSCRIPTION: "/subscription",
  EV: "/ev-users",
  JOINED: "/joined",
  FORUM: "/forum",
  SosCat: "/sos-category",
  PROVIDERS: "/providers",
  Dealer: "/dealer",
  PROCESSING: "/processing",
  MANAGECARDEALERS: "/managecardealers",
  DEALERSUBSCRIBERS: "/subscribers-dealer",
  DEALERHISTORY: `/dealer-history`,
  DEALERPLANS: `/dealer-plans`,
};

// Change initial route if required
routes.LOGIN = routes.INITIAL;

export { routes };
