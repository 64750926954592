import React from "react";
import FeatherIcon from "feather-icons-react";
import { removeStorageItem } from "../../infrastructure/common/local";

const Profile = () => {
  return (
    <div className="profile">
        <FeatherIcon
          onClick={() => {
            removeStorageItem("token");
            window.location.replace("/");
          }}
          icon="log-out"
          color="white"
          style={{ cursor: "pointer" }}
        />
    </div>
  );
};

export default Profile;
