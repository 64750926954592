import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { routes } from "./presentation/common/Routes";
import { theme } from "./presentation/common/Theme/themeVariables";
import { PrimaryLoadingIndicator } from "./presentation/common/UI/LoadingIndicator";
import store from "./redux/store";
import { Provider } from "react-redux";

//import static css to override antd
import "./presentation/common/Style/style.css";
import LayoutProvider from "./presentation/Layout";
import PrivateRoute from "./PrivateRoute";
//component imports
const Login = React.lazy(() => import("./presentation/Login"));
const Signup = React.lazy(() => import("./presentation/Signup"));
const Dashboard = React.lazy(() => import("./presentation/Dashboard"));
const StudentList = React.lazy(() => import("./presentation/ServiceCentre"));
const ExpandedService = React.lazy(() =>
  import("./presentation/ServiceCentre/overview/ExpandedService")
);
const Calendar = React.lazy(() => import("./presentation/Calendar"));
const Category = React.lazy(() => import("./presentation/ServiceRequest"));
const ExpandedRequest = React.lazy(() =>
  import("./presentation/ServiceRequest/overview/RequestExpanded")
);
const Webinars = React.lazy(() => import("./presentation/Reports"));
const Experts = React.lazy(() => import("./presentation/ManageService"));
const ServiceManage = React.lazy(() =>
  import("./presentation/ManageService/Expanded")
);
const Vendor = React.lazy(() => import("./presentation/Vendor"));
const Notification = React.lazy(() => import("./presentation/Notification"));
const Inventory = React.lazy(() => import("./presentation/Inventory"));
const InventoryManage = React.lazy(() =>
  import("./presentation/Inventory/Expanded")
);
const Products = React.lazy(() => import("./presentation/Inventory/Products"));
const ProductCreate = React.lazy(() =>
  import("./presentation/Inventory/Products/CreateProduct.js")
);
const ServiceCreate = React.lazy(() =>
  import("./presentation/ManageService/Service/CreateService")
);
const ServiceEdit = React.lazy(() =>
  import("./presentation/ManageService/EditService/CreateService")
);
const SosExpanded = React.lazy(() => import("./presentation/SOS/Expanded"));
const SOS = React.lazy(() => import("./presentation/SOS"));
const Events = React.lazy(() => import("./presentation/Events"));
const ServiceReport = React.lazy(() => import("./presentation/ReportsService"));
const ProductReport = React.lazy(() =>
  import("./presentation/ReportsProducts")
);
const ServiceExpanded = React.lazy(() =>
  import("./presentation/ManageService/Expanded/ViewService")
);
const Moderators = React.lazy(() => import("./presentation/Moderators"));
const Subscription = React.lazy(() => import("./presentation/Subscription"));
const Ev = React.lazy(() => import("./presentation/EvSubscribers"));
const Joined = React.lazy(() => import("./presentation/JoinedAppUsers"));
const ProductEdit = React.lazy(() =>
  import("./presentation/Inventory/Products/EditProduct")
);
const Forum = React.lazy(() => import("./presentation/Forum"));
const SosCategory = React.lazy(() => import("./presentation/SOS/Category"));
const SosProviders = React.lazy(() => import("./presentation/SOS/Providers"));

const Dealer = React.lazy(() => import("./presentation/Dealer"));
const Processing = React.lazy(() => import("./presentation/Dealer/Processing"));

const ManageCarDealers = React.lazy(() =>
  import("./presentation/Dealer/ManageCarDealers")
);
const DealerSubscribers = React.lazy(() =>
  import("./presentation/Dealer/DealerSubscribers")
);
const DealerPlans = React.lazy(() => import("./presentation/Dealer/Plans"));
const DealerHistory = React.lazy(() =>
  import("./presentation/Dealer/DealerSubscribers/overview/History")
);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider
        theme={{ ...theme, rtl: false, topMenu: false, darkMode: false }}
      >
        <Router>
          <Suspense
            fallback={<PrimaryLoadingIndicator text="Loading..." isFullPage />}
          >
            <Switch>
              <Route exact path={routes.LOGIN} component={Login} />
              <PrivateRoute exact path={routes.SIGNUP} component={Signup} />

              <LayoutProvider>
                <PrivateRoute
                  exact
                  path={routes.DASHBOARD}
                  component={Dashboard}
                />
                <PrivateRoute
                  exact
                  path={routes.STUDENTLIST}
                  component={StudentList}
                />
                <PrivateRoute
                  exact
                  path={routes.SERVICEEXP + "/:id"}
                  component={ExpandedService}
                />
                <PrivateRoute
                  exact
                  path={routes.REQUESTEXP + "/:id"}
                  component={ExpandedRequest}
                />
                <PrivateRoute
                  exact
                  path={routes.MODERATORS}
                  component={Moderators}
                />
                <PrivateRoute
                  exact
                  path={routes.SERVICEMANAGE + "/:id"}
                  component={ServiceManage}
                />
                <PrivateRoute
                  exact
                  // eslint-disable-next-line no-useless-concat
                  path={routes.SERVICEMANAGE + "/:id" + "/:exp"}
                  component={ServiceExpanded}
                />
                <PrivateRoute
                  exact
                  path={routes.INVENTORYEXP + "/:id"}
                  component={InventoryManage}
                />
                <PrivateRoute
                  exact
                  path={routes.PRODUCTCREATE}
                  component={ProductCreate}
                />
                <PrivateRoute
                  exact
                  path={routes.SERVICECREATE}
                  component={ServiceCreate}
                />
                <PrivateRoute
                  exact
                  path={routes.SERVICEEDIT}
                  component={ServiceEdit}
                />
                <PrivateRoute
                  exact
                  path={routes.PRODUCTEDIT}
                  component={ProductEdit}
                />
                <PrivateRoute
                  exact
                  path={routes.SERVICEREPORTS}
                  component={ServiceReport}
                />
                <PrivateRoute
                  exact
                  path={routes.PRODUCTREPORTS}
                  component={ProductReport}
                />
                <PrivateRoute
                  exact
                  path={routes.SUBSCRIPTION}
                  component={Subscription}
                />
                <PrivateRoute exact path={routes.EVENTS} component={Events} />
                <PrivateRoute
                  exact
                  // eslint-disable-next-line no-useless-concat
                  path={routes.INVENTORYEXP + "/:id" + "/:product"}
                  component={Products}
                />
                <PrivateRoute
                  exact
                  path={routes.SOS + "/:id"}
                  component={SosExpanded}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path={routes.INVENTORY}
                  component={Inventory}
                />
                <PrivateRoute exact path={routes.SOS} component={SOS} />
                <PrivateRoute path={routes.CALENDER} component={Calendar} />
                <PrivateRoute
                  exact
                  path={routes.CATEGORY}
                  component={Category}
                />
                <PrivateRoute
                  exact
                  path={routes.WEBINAR}
                  component={Webinars}
                />
                <PrivateRoute exact path={routes.EXPERTS} component={Experts} />
                <PrivateRoute exact path={routes.VENDORS} component={Vendor} />
                <PrivateRoute exact path={routes.EV} component={Ev} />
                <PrivateRoute exact path={routes.JOINED} component={Joined} />
                <PrivateRoute exact path={routes.FORUM} component={Forum} />
                <PrivateRoute
                  exact
                  path={routes.SosCat}
                  component={SosCategory}
                />
                <PrivateRoute
                  exact
                  path={routes.PROVIDERS}
                  component={SosProviders}
                />
                <PrivateRoute
                  exact
                  path={routes.NOTIFICATION}
                  component={Notification}
                />

                {/* super-admin dealer module */}
                <PrivateRoute exact path={routes.Dealer} component={Dealer} />

                <PrivateRoute
                  exact
                  path={routes.PROCESSING}
                  component={Processing}
                />

                <PrivateRoute
                  exact
                  path={routes.MANAGECARDEALERS}
                  component={ManageCarDealers}
                />
                <PrivateRoute
                  exact
                  path={routes.DEALERSUBSCRIBERS}
                  component={DealerSubscribers}
                />
                <PrivateRoute
                  exact
                  path={routes.DEALERPLANS}
                  component={DealerPlans}
                />
                <PrivateRoute
                  exact
                  path={routes.DEALERHISTORY + "/:id"}
                  component={DealerHistory}
                />
              </LayoutProvider>
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
