import React from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch, Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { routes } from "../common/Routes";
import { useHistory } from "react-router-dom";
import {
  categories,
  dashboard,
  experts,
  users,
  review,
} from "../common/Assets/Icons";

const MenuItems = ({ topMenu, events }) => {
  const { path } = useRouteMatch();
  const { SubMenu } = Menu;
  let history = useHistory();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState([
    `${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`,
  ]);

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme="dark"
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={[
        `${
          mainPathSplit.length === 1
            ? "home"
            : mainPathSplit.length === 2
            ? mainPathSplit[1]
            : mainPathSplit[2]
        }`,
      ]}
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        key="dashboard"
        icon={
          <NavLink className="menuItem-icon" to={routes.DASHBOARD}>
            <img alt="" src={dashboard} />
          </NavLink>
        }
      >
        <Link to={routes.DASHBOARD}>Dashboard</Link>
      </Menu.Item>

      <Menu.Item
        key="studenlist"
        icon={
          <NavLink className="menuItem-icon" to={routes.STUDENTLIST}>
            <img alt="" src={users} />
          </NavLink>
        }
      >
        <Link to={routes.STUDENTLIST}>Stores</Link>
      </Menu.Item>
      <Menu.Item
        key="blog"
        icon={
          <NavLink className="menuItem-icon" to={routes.EXPERTS}>
            <img alt="" src={experts} />
          </NavLink>
        }
      >
        <Link to={routes.EXPERTS}>Manage Services</Link>
      </Menu.Item>
      <Menu.Item
        key="inventory"
        icon={
          <NavLink className="menuItem-icon" to={routes.INVENTORY}>
            <img alt="" src={experts} />
          </NavLink>
        }
      >
        <Link to={routes.INVENTORY}>Manage Inventory</Link>
      </Menu.Item>
      <Menu.Item
        key="categories"
        icon={
          <NavLink className="menuItem-icon" to={routes.CATEGORY}>
            <img alt="" src={categories} />
          </NavLink>
        }
      >
        <Link to={routes.CATEGORY}>Service Requests</Link>
      </Menu.Item>
      <Menu.Item
        key="vendor"
        icon={
          <NavLink className="menuItem-icon" to={routes.VENDORS}>
            <img alt="" src={categories} />
          </NavLink>
        }
      >
        <Link to={routes.VENDORS}>Vendors</Link>
      </Menu.Item>
      <SubMenu
        key="reports"
        icon={<img alt="" src={categories} />}
        title="Reports"
      >
        <Menu.Item key="store" onClick={() => history.push(routes.WEBINAR)}>
          Store Wise
        </Menu.Item>
        <Menu.Item
          key="service"
          onClick={() => history.push(routes.SERVICEREPORTS)}
        >
          Service Wise
        </Menu.Item>

        <Menu.Item
          key="products"
          onClick={() => history.push(routes.PRODUCTREPORTS)}
        >
          Product Wise
        </Menu.Item>
      </SubMenu>

      {/* <Menu.Item
        key="review"
        icon={
          <NavLink className="menuItem-icon" to={routes.SERVICEEXP}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.SERVICEEXP}>Reviews</Link>
      </Menu.Item> */}
      <Menu.Item
        key="events"
        icon={
          <NavLink className="menuItem-icon" to={routes.EVENTS}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.EVENTS}>Events</Link>
      </Menu.Item>
      <Menu.Item
        key="forums"
        icon={
          <NavLink className="menuItem-icon" to={routes.FORUM}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.FORUM}>Forums</Link>
      </Menu.Item>
      <Menu.Item
        key="moderators"
        icon={
          <NavLink className="menuItem-icon" to={routes.MODERATORS}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.MODERATORS}>Moderators</Link>
      </Menu.Item>
      {/* <Menu.Item
        key="sos"
        icon={
          <NavLink className="menuItem-icon" to={routes.SOS}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.SOS}>Sos</Link>
      </Menu.Item> */}
      <SubMenu key="sos" icon={<img alt="" src={categories} />} title="Sos">
        <Menu.Item key="store" onClick={() => history.push(routes.SOS)}>
          SOS List
        </Menu.Item>
        <Menu.Item key="service" onClick={() => history.push(routes.SosCat)}>
          SOS Category
        </Menu.Item>

        <Menu.Item
          key="products"
          onClick={() => history.push(routes.PROVIDERS)}
        >
          SOS Providers
        </Menu.Item>
      </SubMenu>

      {/* dealer module */}
      <SubMenu
        key="dealer"
        icon={<img alt="" src={categories} />}
        title="Car dealer"
      >
        <Menu.Item key="store" onClick={() => history.push(routes.PROCESSING)}>
          Currently processing
        </Menu.Item>
        <Menu.Item
          key="service"
          onClick={() => history.push(routes.MANAGECARDEALERS)}
        >
          Manage car dealers
        </Menu.Item>
        <Menu.Item key="sub" onClick={() => history.push(routes.PROCESSING)}>
          Subscription
        </Menu.Item>
        <Menu.Item
          key="subscribrs"
          onClick={() => history.push(routes.DEALERSUBSCRIBERS)}
        >
          Subscribers
        </Menu.Item>
      </SubMenu>

      <Menu.Item
        key="subscription"
        icon={
          <NavLink className="menuItem-icon" to={routes.SUBSCRIPTION}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.SUBSCRIPTION}>Subscription</Link>
      </Menu.Item>
      <Menu.Item
        key="ev"
        icon={
          <NavLink className="menuItem-icon" to={routes.EV}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.EV}>Ev Subscribers</Link>
      </Menu.Item>
      <Menu.Item
        key="joined"
        icon={
          <NavLink className="menuItem-icon" to={routes.JOINED}>
            <img alt="" src={review} />
          </NavLink>
        }
      >
        <Link to={routes.JOINED}>Joined Users</Link>
      </Menu.Item>
      <SubMenu
        key="rewards"
        icon={<img alt="" src={categories} />}
        title="Rewards"
      >
        <Menu.Item key="store" onClick={() => history.push(routes.WEBINAR)}>
          Create price
        </Menu.Item>
        <Menu.Item
          key="service"
          onClick={() => history.push(routes.SERVICEREPORTS)}
        >
          Create levels
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default MenuItems;
